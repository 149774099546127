import { Location } from '../types';
import { isBoundingBox } from './query-bounding-box';
import { isGpsLocation } from './query-gps-location';
import { createQueryLocalities, createQueryBoundingBox, createQueryGpsLocation } from './index';

const createLocation = (location: Location | undefined): Location => {
    if(location && isBoundingBox(location)) {
        return createQueryBoundingBox(location.boundingBoxSearch);
    } else if (location && isGpsLocation(location)) {
        return createQueryGpsLocation(location.gpsLocationSearch);
    }else if(location) {
        return createQueryLocalities(location.searchLocations);
    }else{
        return {
            searchLocations: []
        }
    }
}

export { createLocation }