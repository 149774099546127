import { some } from '../util/functional/Option';
import { serverError, networkError } from '../util/Response';

export const createError = ({ dataBeingFetched }) => (error) => {
    if(error.networkError && typeof error.networkError.statusCode === 'number') {
        return serverError({
            message: `Server error (${error.networkError.statusCode}) fetching data for ${dataBeingFetched}`,
            error: some(error)
        });
    }
    return networkError({
        message: `Network error fetching data from ${dataBeingFetched}`,
        error: some(error)
    });
}
