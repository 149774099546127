import { createLocation, isBoundingBox, formatLocalities, isQueryLocalities, isGpsLocation } from "../location"
import { formatKeywords, formatMealTypes, formatPriceRange } from './format';
import { Channel, QueryFilterArgs, QueryFilters } from "../types"

const urlSlug = (channel: Channel, queryFilters: QueryFilters): string => {
    const components = [
        formatMealTypes(channel, queryFilters.mealTypes),
        formatPriceRange(queryFilters.priceRange)
    ].concat(
        isQueryLocalities(queryFilters.location)
            ? formatLocalities(queryFilters.location)
            : []
    )

    return components.filter(Boolean).join('-');
}

const urlParameters = (
    queryFilters: QueryFilters
): string => {
    const params = {
        keywords: formatKeywords(queryFilters.keywords)
    };

    const paramStrings = Object.entries(params)
        .reduce((acc: Array<string>, curr) => {
            const [key, value] = curr;
            if(typeof value !== "undefined" && value !== "") {
                const paramString = `${key}=${encodeURIComponent(value)}`;
                return [...acc, paramString];
            }else{
                return acc;
            }
        }, [])
        .concat(
            isBoundingBox(queryFilters.location)
                ? `boundingBox=${queryFilters.location.boundingBoxSearch.toString()}`
                : []
        )
        .concat(
            isGpsLocation(queryFilters.location)
                ? `gpsLocation=${queryFilters.location.gpsLocationSearch.toString()}`
                : []
        );
        
        return paramStrings.join('&');
}

const createQueryFilters = ({ 
    location, 
    mealTypes, 
    priceRange,
    keywords 
}: QueryFilterArgs): QueryFilters => {
    return {
        location: createLocation(location),
        mealTypes: mealTypes || [],
        priceRange: priceRange || {},
        keywords: keywords || [],
    }
}

const deepClone = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

const updateQueryFilters =
(queryFilterArgs: QueryFilterArgs) =>
    (originalFilters: QueryFilters): QueryFilters => {

    const {
        location,
        mealTypes,
        priceRange,
        keywords
    } = queryFilterArgs;

    const queryFilters = createQueryFilters(queryFilterArgs);
    const deepCloneOriginalFilters = deepClone(originalFilters);

    return {
        location: location ? queryFilters.location : deepCloneOriginalFilters.location,
        mealTypes: mealTypes ? queryFilters.mealTypes : deepCloneOriginalFilters.mealTypes,
        priceRange: priceRange ? queryFilters.priceRange : deepCloneOriginalFilters.priceRange,
        keywords: keywords ? queryFilters.keywords : deepCloneOriginalFilters.keywords,
    }
    
}

export {
    createQueryFilters,
    urlParameters,
    urlSlug,
    updateQueryFilters,
    deepClone
}