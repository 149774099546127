import {
    SearchQuery,
    QueryFilters,
    Channel,
    SearchMode,
    SortTypeValue
} from "../types";
import { urlSlug, urlParameters } from "../query-filters";
import { sortTypeQueryToUrl } from "../sort-types";

const toPathname = ({
    channel,
    searchMode,
    page,
    filters
}: {
    channel: Channel;
    searchMode: SearchMode;
    page: number;
    filters: QueryFilters;
}): string => {
    const isFirstPage = page === 1;
    const searchModePage = !isFirstPage ? `${searchMode}-${page}` : null;

    const components = [
        channel,
        urlSlug(channel, filters),
        searchModePage
    ].filter(Boolean);

    return isFirstPage ?
        `/${components.join('/')}/` :
        `/${components.join('/')}`;
}

const addComponentToQueryParams =
    (name: string, value: string | undefined) =>
    (queryParams: string): string => {
        if(value === undefined) return queryParams;
        const urlComponent = `${name}=${encodeURIComponent(value)}`;
        return queryParams ? `${queryParams}&${urlComponent}` : urlComponent;
    }

const toQueryParams = ({
    channel,
    sortType,
    filters,
    searchMode
}: {
    channel: Channel;
    sortType: SortTypeValue | undefined;
    filters: QueryFilters;
    searchMode: SearchMode;
}): string => {
    const sortTypeValue = sortType
        ? sortTypeQueryToUrl(channel, sortType)
        : undefined;
        const addSortType = addComponentToQueryParams("activeSort", sortTypeValue);
        const queryParams =  addSortType(urlParameters(filters));
        return queryParams ? `?${queryParams}` : "";
}

const generateSearchResulPathname = ({
    channel,
    searchMode,
    page,
    filters
}: SearchQuery): string => {
    return toPathname({
        channel,
        searchMode,
        page,
        filters
    })
}

export const generateSearchResultUrl = (searchQuery: SearchQuery): string => {
    const pathName = generateSearchResulPathname(searchQuery);
    const { channel, sortType, filters, searchMode } = searchQuery;
    const queryParams = toQueryParams({
        channel,
        sortType,
        filters,
        searchMode
    });
    return `${pathName}${queryParams}`;
}
