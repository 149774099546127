import SECTION_MEAL_TYPES from './meal-types.json';
import { Channel, JsonMealTypes } from "../types";

const mealTypes = SECTION_MEAL_TYPES as JsonMealTypes;

const MEAL_TYPES: {
    meals: JsonMealTypes;
    restaurants: JsonMealTypes;
} = {
    meals: mealTypes,
    restaurants: mealTypes
}

const getMealTypeValues = (channel: Channel): Array<string> => MEAL_TYPES[channel].map(({ value }) => value)

const createSortMealsOrder = (
    channel: Channel,
): (( a: string, b: string) => number) => {
    const mealTypes = getMealTypeValues(channel);
    return function (a, b) {
        return mealTypes.indexOf(a) - mealTypes.indexOf(b);
    }
}

const sortMealTypes = (
    channel: Channel,
    mealTypes: Array<string>
): Array<string> => {
    return mealTypes.sort(createSortMealsOrder(channel));
}

const normalizeCuisineTypes = (
    channel: Channel,
    mealTypes: Array<string> = []
): Array<string> => {
    const containsAll = mealTypes.indexOf('all') > -1;
    return containsAll ? [] : mealTypes;
}

export {
    sortMealTypes,
    mealTypes,
    normalizeCuisineTypes
}
