import {
    SearchUrl,
    SearchQuery,
    Channel,
    SearchMode,
    SortTypeValue
} from '../types';
import { parseUrlToQueryFilters, takeTokenAfter } from './parse-url-to-filters';
import { sortTypesUrlToQuery } from '../sort-types';

const DEFAULT_SEARCH_MODE = 'list';
const DEFAULT_PAGE = 1;

const captureUrlSegments = (urlString: string): SearchUrl | never => {
    const { pathname, search } = new URL(
        urlString,
        "https://nearbites.com"
    )

    const pathnameRegExp = /^\/(meals|restaurants)(\/.+)?\/(list|map)?[-]?(\d+)?$/;
    const pathnameComponents = pathnameRegExp.exec(pathname)
    if (!pathnameComponents) {
        throw new Error(`URL "${urlString}" does not match Search URL RegExp`)
    }

    const slugString = pathnameComponents[2]
        ? pathnameComponents[2].replace(/\+/g, " ")
        : "";
    const searchMode = pathnameComponents[3]
        ? pathnameComponents[3]
        : DEFAULT_SEARCH_MODE;
    const page = pathnameComponents[4]
        ? parseInt(pathnameComponents[4])
        : DEFAULT_PAGE;
    const params = search.substring(1).split(/[=&]/);

    return {
        channel: pathnameComponents[1] as Channel,
        slug: slugString.substring(1).split("-"),
        searchMode: searchMode as SearchMode,
        page,
        params
    }
}

const getSortType = (channel: Channel, sortTypeUrlValue: string): SortTypeValue => sortTypesUrlToQuery(channel, sortTypeUrlValue);

const parseSearchUrl = (urlString: string): SearchQuery => {
    const url = captureUrlSegments(urlString);
    const { channel, page, params, searchMode } = url;
    const sortTypeUrlValue = takeTokenAfter("activeSort", params);
    const sortType = getSortType(channel, sortTypeUrlValue);
    const filters = parseUrlToQueryFilters(url);

    return {
        channel,
        filters,
        page,
        sortType,
        searchMode
    }
}

export {
    parseSearchUrl
}
