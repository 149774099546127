export { default as SearchQueryReducer } from './searchQueryReducer';

export const getSearchText = (channel, mealTypes, keywords) => {
    const all = [
        ...mealTypes,
        ...keywords
    ];

    return all.length
        ? all.join(', ')
        : `${channel.charAt(0).toUpperCase()}${channel.slice(1)}`;
};

export const getLoactionText = (location) => {
    const locationComponents = [];

    locationComponents.push('near')
    if ("searchLocations" in location && location.searchLocations.length) {
        locationComponents.push(location.searchLocations.join(', '))
    } else {
        locationComponents.push('me')
    }

    return `${locationComponents.join(' ')}`;
};