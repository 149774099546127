import React from 'react';
import styled from "styled-components";
import LinkBase from './link-base';
import forwardRef from "../../private/forwardRef"
import { As } from "../../private/forwardRef/forwardRef.types"
import {
    LinkProps,
    LinkIconPlacement,
    LinkVariant,
    LinkAsButtonVariant,
    LinkSize
} from "./link.types"
import {NittyTheme} from "../../";
import ButtonBase from "../button/button-base";

type LinkWithButtonVariantProps = {
    $externalAs: As
}

const LinkWithButtonVariant = styled(
    ButtonBase
).attrs<LinkWithButtonVariantProps>(({ $externalAs }) => ({ as: $externalAs || "a" }))`
    text-decoration: none;
    display: flex;
    text-align: center;
    justify-content: center;
    width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "fit-content")};
`;

type SpanWithMarginProps = {
    theme: NittyTheme
    iconPlacement: LinkIconPlacement
    buttonVariationMatches: boolean
}

const SpanWithMargin = styled.span<SpanWithMarginProps>`
    ${({ iconPlacement, buttonVariationMatches, theme }) =>
        `margin-${iconPlacement}: ${
            buttonVariationMatches
                ? theme.spacing.extraSmall
                : theme.spacing.twoExtraSmall
        }`};
`;

const IconWrapper = styled.span`
    display: inline-flex;
    vertical-align: text-top;
`

const defaultProps = {
    variant: "primary" as LinkVariant | LinkAsButtonVariant,
    sizeVariant: "large" as LinkSize,
    icon: null,
    hideLabel: false,
    iconPlacement: "left" as LinkIconPlacement,
    fullWidth: false,
    compact: false,
}

const Link = forwardRef<LinkProps, "a">((props, ref) => {
    const {
        children,
        variant,
        icon,
        iconPlacement,
        hideLabel,
        sizeVariant,
        fullWidth,
        compact,
        dangerouslySetClassNames,
        as,
        ...rest
    } = { ...defaultProps, ...props }

    const buttonVariationMatches = variant.match(/button-(.*)/)
    const variantName = buttonVariationMatches ? buttonVariationMatches[1] : variant;
    const asProp = buttonVariationMatches ? { $externalAs: as } : { as };

    const renderIcon = () => {
        if(icon) {
            return buttonVariationMatches ? (
                icon
            ) : (
                <IconWrapper className={dangerouslySetClassNames?.iconWrapper}>
                    {icon}
                </IconWrapper>
            )
        }
    }

    const renderChildren = () => icon ? (
        <SpanWithMargin
            iconPlacement={iconPlacement}
            className={dangerouslySetClassNames?.label}
            buttonVariationMatches={!!buttonVariationMatches}
        >
            {children}
        </SpanWithMargin>
    ) : (
        children
    )

    const commonProps = {
        "aria-label": hideLabel ? children : null,
        $variant: variantName,
        $fullWidth: fullWidth,
        $compact: compact,
        $sizeVariant: sizeVariant,
        ref,
        ...asProp,
        ...rest
    }

    const renderIconAndLabel = () => (
        <>
            {iconPlacement === "left" && renderIcon()}
            {!hideLabel ? renderChildren() : null}
            {iconPlacement === "right" && renderIcon()}
        </>
    )

    return buttonVariationMatches ? (
        <LinkWithButtonVariant {...commonProps}>
            {renderIconAndLabel()}
        </LinkWithButtonVariant>
    ) : (
        <LinkBase {...commonProps}>{renderIconAndLabel()}</LinkBase>
    )

});

Link.displayName = "Link";

export default Link;
export { LinkBase };
