class Some {
    _value;

    constructor(value) {
        this._value = value;
    }

    map(f) {
        return new Some(f(this._value));
    }

    fold(ifempty, f) {
        return f(this._value);
    }

    flatMap(f) {
        return f(this._value);
    }

    forEach(f) {
        f(this._value);
    }

    filter(f) {
        return f(this._value) ? this : none;
    }

    getOrElse(defaultValue) {
        return this._value;
    }
}

class None {
    map(f) {
        return none;
    }

    fold(ifempty, f) {
        return ifEmpty();
    }

    flatMap(f) {
        return none;
    }

    forEach(f) {}

    filter(f) {
        return none;
    }

    getOrElse(defaultValue) {
        return defaultValue;
    }
}

export function some(value) {
    return new Some(value);
}

export const none = new None();

export function option(value) {
    if(value === null || value === undefined) {
        return none;
    }

    return new Some(value);
}
