import React from 'react';
import Helmet from 'react-helmet';

export default ({ title, meta = [], link = [], script = [] }) => {
    return (
        <Helmet>
            <title>{title}</title>
            {meta.map((d,i) => React.createElement('meta', { ...d, key: i }))}
            {link.map((d,i) => React.createElement('link', { ...d, key: i }))}
            {script.map((d,i) => React.createElement('script', { ...d, key: i }))}
        </Helmet>
    );
};
