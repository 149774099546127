import { isValidBoundingBoxLength, isValidGpsLength } from '../location';
//import { normalizeCuisineTypes } from "../meal-types";
import { createQueryFilters } from '../query-filters';
import { QueryFilters, SearchMode, SearchUrl, Channel, QueryFilterArgs, Localities, BoundingBox, GpsLocation } from '../types';

const ORDERED_SLUG_PARAMS = ['meal', 'between', 'near'];

const getTokensAfter = (text: string, tokens: Array<string>): Array<string> => {
    const foundIndex = tokens.indexOf(text);
    if (foundIndex === -1) {
        return []
    }

    let nextParam;
    for(let i = 0; i < ORDERED_SLUG_PARAMS.length; i++) {
        const checkParam = ORDERED_SLUG_PARAMS[i];
        if(checkParam != text && tokens.indexOf(checkParam) != -1) {
            nextParam = checkParam;
            break;
        }
    }

    if(nextParam) {
        const nextParamInex = tokens.indexOf(nextParam);

        if(nextParamInex > foundIndex) {
            return tokens.slice(foundIndex + 1, nextParamInex);
        }
    }

    return tokens.slice(foundIndex + 1);
}

const takeTokenAfter = (text: string, tokens: Array<string>) => {
    return getTokensAfter(text, tokens)[0]
}

const parseKeywords = (keywords: string | undefined): Array<string> => {
    return keywords
        ? decodeURIComponent(keywords)
            .split(',')
            .map((keyword) => keyword.trim())
        : []
}

const getFilterArgs = (channel: Channel, slug: Array<string>, params: Array<string>, searchMode: SearchMode): QueryFilterArgs => {
    const mealTypes = getTokensAfter("meal", slug);
    const priceRange = getTokensAfter("between", slug).slice(0,2);
    const keywords = parseKeywords(takeTokenAfter("keywords", params));

    return {
        mealTypes,
        priceRange: { minimum: priceRange[0], maximum: priceRange[1]},
        keywords
    }
}

const getLocalities = (slug: Array<string>): Localities => {
    const localitiesSlug = getTokensAfter("near", slug).join("-");
    const decodedString = decodeURIComponent(localitiesSlug).replace(/\+/g, " ");
    return {
        searchLocations: decodedString.split(";").filter(Boolean)
    }
}

const getBoundingBox = (params: Array<string>): BoundingBox => {
    const boundingBoxParam = takeTokenAfter("boundingBox", params);
    const boundingBoxArr = boundingBoxParam ? boundingBoxParam.split(',') : [];
    const boundingBoxValues = boundingBoxArr.map(Number).filter((n) => !Number.isNaN(n));

    return {
        boundingBoxSearch: boundingBoxValues
    }
}

const getGpsLocation = (params: Array<string>): GpsLocation => {
    const gpsLocationParam = takeTokenAfter("gpsLocation", params);
    const gpsLocationArr = gpsLocationParam ? gpsLocationParam.split(',') : [];
    const gpsLocationValues = gpsLocationArr.map(Number).filter((n) => !Number.isNaN(n));

    return {
        gpsLocationSearch: gpsLocationValues
    }
}

const parseUrlToQueryFilters = (url: SearchUrl): QueryFilters => {
    const { slug, channel, params, searchMode } = url;
    const filterArgs = getFilterArgs(channel, slug, params, searchMode);

    const localities = getLocalities(slug);
    const boundingBox = getBoundingBox(params);
    const gpsLocation = getGpsLocation(params);

    const location = isValidBoundingBoxLength(boundingBox.boundingBoxSearch)
        ? boundingBox
        : isValidGpsLength(gpsLocation.gpsLocationSearch) ? gpsLocation : localities;

    return createQueryFilters({ location, ...filterArgs })
}

export { parseUrlToQueryFilters, takeTokenAfter }
