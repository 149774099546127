import { Channel, Range } from "../types";
import { sortMealTypes } from '../meal-types';

const formatMealTypes = (
    channel: Channel,
    mealTypes: Array<string>
): string => {
    if(!mealTypes.length) {
        return ""
    }

    const sortedTypes = sortMealTypes(channel, mealTypes);
    const mealTypesString = sortedTypes.join('-').replace(/ /g, '+');
    return `meal-${mealTypesString}`
}

const formatPriceRange = (priceRange: Range): string => {
    if(!(priceRange.minimum || priceRange.maximum)) {
        return "";
    }
    return `between-${priceRange.minimum || 0}-${priceRange.maximum || "any"}`;
}

const formatKeywords = (
    keywords: Array<string>
): string => {
    return keywords.filter((val, index) => keywords.indexOf(val) === index)
    .join(',');
}

export {
    formatMealTypes,
    formatPriceRange,
    formatKeywords
}
