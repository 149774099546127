class Success {
    value;

    constructor(value) {
        this.value = value;
    }

    map(f) {
        return new Success(f(this.value));
    }

    flatMap(f) {
        return f(this.value);
    }

    fold({ifSuccess}) {
        return ifSuccess(this.value);
    }
}

class NotFound {
    map(f) {
        return this;
    }

    flatMap(f) {
        return this;
    }

    fold({ifNotFound}) {
        return ifNotFound();
    }
}

class ServerError {
    value;

    constructor(value) {
        this.value = value;
    }

    map(f) {
        return this;
    }

    flatMap(f) {
        return this;
    }

    fold({ifServerError}) {
        return ifServerError(this.value);
    }
}

class PermanentRedirect {
    value;

    constructor(value) {
        this.value = value;
    }

    map(f) {
        return this;
    }

    flatMap(f) {
        return this;
    }

    fold({ifPermanentRedirect}) {
        return ifPermanentRedirect(this.value);
    }
}

class NetworkError {
    value;

    constructor(value) {
        this.value = value;
    }

    map(f) {
        return this;
    }

    flatMap(f) {
        return this;
    }

    fold({ifNetworkError}) {
        return ifNetworkError(this.value);
    }
}

export const success = (data) => new Success(data);
export const notFound = new NotFound();
export const serverError = (error) => new ServerError(error);
export const permanentRedirect = (url) => new PermanentRedirect(url);
export const networkError = (error) => new NetworkError(error);
