import React, { useState, useRef, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { paletteHelper, spacingHelper, H2, Text, Button, P } from '../../../../@nitty';
import useSearch from '../../../../util/search/useSearch';
import homeQuery from '../../searchResultsQuery.graphql';
import MealCard from '../../../../components/meal-card';
import SEARCH_QUERY from '../../searchResultsQuery.graphql';
import createVariables from '../../createVariables';

const CARDS_PER_AD = 5;
const PAGE_SIZE = 25;

const ResultsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: ${({ theme }) => theme.spacing.large};
    justify-items: center;
    padding: ${spacingHelper("0 medium")};
    padding-bottom: 3rem;

    ${({ theme }) => theme.layoutBreakpoints.medium} {
        max-width: calc(
            ${({ theme }) => theme.maxWidth} + ${spacingHelper("medium")}
        );
        grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
        padding: ${spacingHelper("0 extraLarge")};
        padding-bottom: 5rem;
        margin: ${spacingHelper('0 auto')};
    }
`

const NoResults = () => {
    return (
        <p>No results found</p>
    )
}

const checkNextPage = ({ nextPage, totalPageCount }) => nextPage <= totalPageCount;

const ResultsList = ({ 
    results: initialResults, 
    pageInfo: initialPageInfo
}) => {
    const { searchQuery } = useSearch();
    const [results, setResults] = useState(initialResults);
    const [pageInfo, setPageInfo] = useState(initialPageInfo);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [fetchSearchResults, { data, loading, error, client }] = useLazyQuery(SEARCH_QUERY);
    const observer = useRef();
    const lastResultRef = useRef(null);

    const handleLoadMore = async () => {
        if(!hasNextPage) return;

        const previousQuery = client.readQuery({
            query: SEARCH_QUERY,
            variables: createVariables({ searchQuery })
        });

        const nextPageNumber = pageInfo.page + 1;

        const response = await fetchSearchResults({
            variables: createVariables({
                searchQuery: {
                    ...searchQuery,
                    page: nextPageNumber
                }
            })
        })

        // Clean up cache for 
        client.writeQuery({
            query: SEARCH_QUERY,
            variables: createVariables({
                searchQuery: {
                    ...searchQuery,
                    page: nextPageNumber
                }
            }),
            data: {
                search: {
                    ...response.data.search,
                    results: []
                }
            }
        });

        const combinedResults = [...results, ...response.data.search.results];
        client.writeQuery({
            query: SEARCH_QUERY,
            variables: createVariables({ searchQuery }),
            data: {
                search: {
                    ...previousQuery.search,
                    results: combinedResults
                }
            }
        }); 
    };

    useEffect(() => {
        if (loading || error) return;

        if (data) {
            const combinedResults = [...results, ...data.search.results];
            const hasNextPage = checkNextPage({ nextPage: data.search.pageInfo.page + 1, totalPageCount: initialPageInfo.totalPageCount });

            setResults(combinedResults);
            setPageInfo(data.search.pageInfo);
            setHasNextPage(hasNextPage);
        }
    }, [data, loading, error]);

    useEffect(() => {
        setResults(initialResults);
    }, [initialResults])

    useEffect(() => {
        setPageInfo(initialPageInfo);

        const hasNextPage = checkNextPage({ 
            nextPage: initialPageInfo.page + 1, 
            totalPageCount: initialPageInfo.totalPageCount 
        })
        setHasNextPage(hasNextPage);
    }, [initialPageInfo])

    useEffect(() => {
        if (!hasNextPage) return;

        const options = {
            rootMargin: '100%',
        };

        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                handleLoadMore();
            }
        }, options);

        if (lastResultRef.current) {
            observer.current.observe(lastResultRef.current);
        }

        return () => {
            if (observer.current) {
                observer.current.disconnect();
            }
        };
    }, [results, hasNextPage])

    const renderResults = () => {
        const renderedResults = [];
        const numResults = results ? results.length : 0;
        const numResultsAndLoading = numResults + (loading ? PAGE_SIZE : 0);
        const isAdSpot = index => index && !((index + 1) % CARDS_PER_AD)

        for (let index = 0; index < numResultsAndLoading; index++) {
            if (index >= numResults) {
                renderedResults.push(<MealCard key={index} />)
            }else{
                const meal = results[index];
                renderedResults.push(<MealCard key={index} meal={meal} ref={numResults === index + 1 ? lastResultRef : null} />);
            }
            // if (isAdSpot(index)) {
            //     // renderResult.push(renderAdCard(index))
            //     renderedResults.push(<div key={`ad-${index}`}>This is an ad</div>);
            // }
        }

        return renderedResults.length ? renderedResults : <NoResults />;
    }

    return (
        <>
            <ResultsContainer>
                {renderResults()}
            </ResultsContainer>
        </>
    );
};

export default ResultsList;
