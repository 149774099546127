import React, { useState, useRef, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { spacingHelper, typographyHelper, Text, H2, H3, Link, Tabs, TabBar, Tab, TabList } from '../../../../@nitty';
import useSearch from '../../../../util/search/useSearch';

const Container = styled.div`
    border-top: 1px solid #e9e9e9;

    .list-item {
        margin-right: 1rem;
    }

    .tab-button {
        
    }
`

const Content = styled.div`
    max-width: calc(
        ${({ theme }) => theme.bodyMaxWidth} + ${spacingHelper("medium")}
    );
    margin: auto;
    padding: ${spacingHelper("medium medium")};

    ${({ theme }) => theme.layoutBreakpoints.medium} {
        padding: ${spacingHelper("threeExtraLarge extraLarge")};
    }
`;

const MainHeader = styled(H2)`
    margin: 0 0 1rem;
`

const TabHeader = styled(H3)`
    ${typographyHelper("subtitle01")}
    margin: 0 0 1rem;
`

const Grid = styled.ul`
    margin-top: 1.25rem;
    display:grid;
    row-gap: ${spacingHelper("large")};
    grid-template-columns: auto;
    column-gap: 0.875rem;
    row-gap: 1rem;

    ${({ theme }) => theme.layoutBreakpoints.small} {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    ${({ theme }) => theme.layoutBreakpoints.medium} {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    ${({ theme }) => theme.layoutBreakpoints.large} {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
`

const StyledLink = styled(Link).attrs({
    variant: "secondary"
})`
    text-decoration: none;
    &:hover {
        color: black;
        text-decoration: none;
    }
`;

const ResultsFooter = ({ sections }) => {
    const { searchQuery } = useSearch();
    const { channel } = searchQuery;
    const [selectedTab, setSelectTab] = useState(0);

    return (
        <Container>
            <Content>
                <MainHeader variant="title03">{`Popular searches for ${channel}`}</MainHeader>
                <Tabs selectedTab={selectedTab}>
                    <TabBar>
                        <TabList>
                            {sections.map(({ title }, index) => (
                                <Tab
                                    key={title}
                                    onClick={() => setSelectTab(index)}
                                    dangerouslySetClassNames={{ tabButton: 'tab-button', listItem: 'list-item' }}
                                >
                                    {title}
                                </Tab>
                            ))}
                        </TabList>
                    </TabBar>
                    { sections.map(({ subtitle, items }, index) => (
                        <div key={index} hidden={!(index === selectedTab)}>
                            <TabHeader>{subtitle}</TabHeader>
                            <Grid>
                                { items.map((item, itemIndex) => (
                                    <li key={itemIndex}>
                                        <StyledLink href={item.path} variant="secondary">
                                            {item.name}
                                        </StyledLink>
                                    </li>
                                ))}
                            </Grid>
                        </div>
                    ))} 
                </Tabs>
            </Content>
        </Container>
    );
};

export default ResultsFooter;
