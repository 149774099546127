const KMS_PER_HOUR = 5;

export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    const R = 6371;
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
};

export const getWalkingTime = (lat1, lon1, lat2, lon2) => {
    const distance = getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2);
    let time = (distance / KMS_PER_HOUR) * 60;
    let format = 'mins';

    if (time < 1) {
        time = 1;
    } else if (time <= 60) {
        time = Math.round(time);
    } else if (time <= (24 * 6)) {
        time = Number(time / 60).toFixed(2);
        format = 'hrs'
    } else {
        time = Number(time / (60 * 24)).toFixed(2);
        format = 'days'
    }

    return `${time} ${format}`;
};

const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
};

const adaptData = ({ meal }, searchQuery) => {
    if(!meal) return { loading: true }

    const { id, name, mainImage, price, restaurant, images, path } = meal;

    const { address: { location }} = restaurant;

    const gpsLocation = searchQuery.filters.location.gpsLocationSearch;

    const distance = gpsLocation
        ? getWalkingTime(gpsLocation[1], gpsLocation[0], location.latitude, location.longitude)
        : null;

    return {
        id,
        name,
        mainImage,
        price,
        restaurant,
        images,
        path,
        distance
    }
}

export default adaptData;
