import { SITE_NAME, SITE_URL, IOS_APP_ID, FACEBOOK_APP_ID } from '../../../../config/environment';

const DESCRIPTION = `${SITE_NAME} provides you an easier way to find food. No need to find the restaurant first - go straight to the meal. Satisfy your cravings in seconds.`
const TITLE = `${SITE_NAME} - You look hungry`
const DEFAULT_IMAGE = 'https://nearbites.com/social-image.png'

export const generateTitle = (title = TITLE) => title;

export const generateDescription = (content = DESCRIPTION) => ({
    name: 'description',
    content
});

export const generateCanonical = (path = '') => ({
    rel: 'canonical',
    href: `${SITE_URL}${path}`
});

export const generateOpenGraphTitle = (content = TITLE) => ({
    property: 'og:title',
    content
});

export const generateOpenGraphDescription = (content = DESCRIPTION) => ({
    property: 'og:description',
    content
});

export const openGraphImageWidth = {
    property: 'og:image:width',
    content: '800'
};

export const openGraphImageHeight = {
    property: 'og:image:height',
    content: '600'
};

export const generateOpenGraphImage = (content = DEFAULT_IMAGE) => ({
    property: 'og:image',
    content
});

export const generateOpenGraphUrl = (path = '') => ({
    property: 'og:url',
    content: `${SITE_URL}${path}`
});

export const openGraphSiteName = {
    property: 'og:site_name',
    content: 'nearbites.com'
};

export const generateAppleSmartBanner = (path) => {
    const appArguments = path ? `, app-arguments=${SITE_URL}${path}` : '';

    return {
        name: 'applie-itunes-app',
        content: `app-id=${IOS_APP_ID}${appArguments}`
    }
}

export const generateGoogleSmartBanner = () => {
    return {
        name: 'google-play-app',
        content: `app-id=${GOOGLE_APP_ID}`
    }
};

const FACEBOOK_DATA = [
    {
        property: 'fb:app_id',
        content: FACEBOOK_APP_ID
    }
];

const TWITTER_DATA = [
    {
        property: 'twitter:card',
        content: 'summary_large_image'
    },
    {
        property: 'twitter:site',
        content: '@nearbites'
    }
];

export const socialMediaData = [...TWITTER_DATA];
