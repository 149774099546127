export type Range = {
    minimum?: string,
    maximum?: string
}

export type QueryFilters = {
    location: Location;
    mealTypes: Array<string>;
    priceRange: Range;
    keywords: Array<string>;
}

export type JsonMealTypes = Array<{
    label: string;
    seoDisplay: string;
    value: string;
}>

export type SortTypeValue = "relevance" | "new-desc" | "new-asc" | "price-desc" | "price-asc";

export type SortType = {
    value: SortTypeValue;
    label: string;
    urlValue?: string;
}

type FilterOption = {
    value: string;
    label: string;
    abbreviation?: string;
    urlValue?: string;
    disabled?: boolean;
}

export type Options = Array<FilterOption>;

type FilterOptions = {
    price: Options;
    priceLabel: string;
    mealTypes: JsonMealTypes;
}

export type ChannelViewConfigurationType = {
    title: string;
    priceOnly: Boolean;
    sortTypes: Array<SortType>;
    filterOptions: FilterOptions;
}

export type SearchMode = "list" | "map";

export type SearchMealType = "asain" | "italian";

export enum ChannelType {
    MEALS = "meals",
    RESTAURANTS = "restaurants"
}

export type Channel = ChannelType.MEALS | ChannelType.RESTAURANTS;

export type SearchQuery = {
    channel: Channel;
    filters: QueryFilters;
    page: number;
    pageSize?: number;
    sortType?: SortTypeValue,
    searchMode: SearchMode
};

export type SearchUrl = {
    channel: Channel;
    slug: Array<string>;
    page: number;
    params: Array<string>;
    searchMode: SearchMode;
}

export type QueryFilterArgs = {
    location?: Location;
    mealTypes?: Array<string>;
    priceRange?: Range;
    keywords?: Array<string>;
}

export type SearchQueryArgs = {
    channel?: Channel,
    page?: number,
    pageSize?: number,
    sortType?: SortTypeValue,
    searchMode?: SearchMode;
} & QueryFilterArgs;

export type Localities = {
    searchLocations: Array<string>
}

export type BoundingBox = {
    boundingBoxSearch: Array<number>
}

export type GpsLocation = {
    gpsLocationSearch: Array<number>
}

export type Location = Localities | BoundingBox | GpsLocation;
