const adaptData = ({ search }) => {
    const { results, pageInfo } = search;
    
    return {
        results,
        pageInfo
    }
};

export default adaptData;
