import { SearchQuery, SearchQueryArgs } from "../types";
import { updateQueryFilters, deepClone } from '../query-filters';

const updateSearchQuery = ({
    channel,
    location,
    mealTypes,
    priceRange,
    keywords,
    page,
    sortType,
    searchMode,
    pageSize
}: SearchQueryArgs) =>
(originalSearchQuery: SearchQuery): SearchQuery => {
    const deepClonedOriginalSearchQuery = deepClone(originalSearchQuery);
    const originalFilters = deepClonedOriginalSearchQuery.filters;
    const updatedFilters = updateQueryFilters({
        location,
        mealTypes,
        priceRange,
        keywords
    })(originalFilters);

    const searchQuery: SearchQuery = {
        channel: channel || deepClonedOriginalSearchQuery.channel,
        filters: updatedFilters,
        page: page || deepClonedOriginalSearchQuery.page,
        sortType: sortType || deepClonedOriginalSearchQuery.sortType,
        searchMode: searchMode || deepClonedOriginalSearchQuery.searchMode
    }

    return searchQuery;
}

export {
    updateSearchQuery
}