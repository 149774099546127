import { Location, Localities } from "../types";

const createQueryLocalities = (localities: Array<string>): Localities => {
    const searchLocations = localities.map((l) => l.trim()).filter(Boolean);
    return {
        searchLocations
    }
}

const formatLocalities = (queryLocalities: Localities): string => {
    if(!queryLocalities.searchLocations.length) {
        return "";
    }

    const prettyLocalities = encodeURIComponent(
        queryLocalities.searchLocations.join("; ")
    )
        .replace(/%20/g, "+")
        .replace(/%2C/g, ",")

    return `near-${prettyLocalities}`;
}

const isQueryLocalities = (location: Location): location is Localities =>
    (location as Localities).searchLocations !== undefined;

export {
    createQueryLocalities,
    formatLocalities,
    isQueryLocalities
}
