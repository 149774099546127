import { ChannelViewConfiguration } from '../channel-view-configuration';
import { Channel, SortType, SortTypeValue } from '../types';

const availableTypes = (channel: Channel): Array<SortType> => {
    return ChannelViewConfiguration[channel].sortTypes;
}

const queryToUrl = (
    channel: Channel,
    query: SortTypeValue
): string | undefined => 
    availableTypes(channel).filter((s) => s.value === query)[0]?.urlValue;

const urlToQuery = (channel: Channel, param: string) => availableTypes(channel).filter((s) => s.urlValue === param)[0]?.value;

export { queryToUrl as sortTypeQueryToUrl, urlToQuery as sortTypesUrlToQuery }