import React from "react";
import {
    WalkingMd,
    SpriteSheet
} from '../../@nitty'

export const SPRITES = {
    WALKING: 'walking'
};

export const MealSpriteSheet = () => (
    <SpriteSheet 
        sprites={{
            [SPRITES.WALKING]: <WalkingMd />
        }}
    />
)

export default MealSpriteSheet;