import React from 'react'
import styled from 'styled-components'
import Link from '../link'
import {
    paletteHelper,
    spacingHelper,
    H2,
    Text,
    Button,
    Skeleton,
    SkeletonText,
    Stack,
    Inline,
    Card,
    Sprite,
    typographyHelper
} from '../../@nitty'
import { SPRITES } from '../sprites';
import { TemplatedLazyImage } from '../image';
import placeholderSrc from './placeholder.svg';

const MealCard = styled.div`
    position: relative;
    width: 100%;
`;

const MealCardBody = styled.div`
    display: flex;
    flex-direction: column-reverse;
`;
const MealCardImageWrapper = styled.div`
    aspect-ratio: 4/3;
    border-radius: 0.75rem;
    overflow: hidden;
`;

const MealCardPicture = styled(TemplatedLazyImage)`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    background-color: ${({ theme }) => `${theme.palette.backgroundSecondary}`};
`;

const MealCardInfoPanel = styled.div`
`;

const CardLink = styled(Link)`
    display: block;
    text-decoration: none;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        border-radius: 0.75rem;
    }
`;

const Price = styled(Text).attrs(() => ({
    as: 'div'
}))`
    color: ${paletteHelper("textSecondary")};
`;

const MealName = styled(Text).attrs(() => ({
    as: 'span',
}))`
    ${typographyHelper("body01")}
    ${typographyHelper("strong")}
    color: ${paletteHelper("textPrimary")};
`;

const StyledH2 = styled(H2)`
    line-height: 1rem;
`

const Distance = styled(Text).attrs(() => ({
    as: 'div'
}))`
    display: flex;
    align-items: center;
    color: ${paletteHelper("textSecondary")};
`;

const View = React.forwardRef(({ loading, mainImage, id, name, price, restaurant, images, path, distance, ...restProps }, ref) => {
    if (loading) {
        return (
            <MealCard ref={ref}>
                <MealCardBody>
                    <MealCardInfoPanel>
                        <Stack inset="small">
                            <Stack gap="extraSmall">
                                <SkeletonText />
                                <SkeletonText height="overline" />
                            </Stack>
                            <Inline grow={false} gap="small">
                                <SkeletonText width="3rem" />
                                <SkeletonText width="3rem" />
                            </Inline>
                        </Stack>
                    </MealCardInfoPanel>
                    <MealCardImageWrapper>
                        <Skeleton height="100%" width="100%" borderRadius="0" />
                    </MealCardImageWrapper>
                </MealCardBody>
            </MealCard>
        )
    }

    return (
        <MealCard {...restProps} ref={ref}>
            <meta itemProp="name" content={name} />
            <meta itemProp="url" content={path} />
            <CardLink to={path} rel="noopener noreferrer nofollow"></CardLink>
            <MealCardBody>
                <MealCardInfoPanel>
                    <Stack inset="small">
                        <Stack gap="extraSmall">
                            <StyledH2>
                                <CardLink to={path} rel="noopener noreferrer nofollow">
                                    <MealName>{name}</MealName>
                                </CardLink>
                            </StyledH2>
                            <Text as="div" variant="overline">{restaurant.name}</Text>
                        </Stack>
                        <Inline grow={false} gap="small">
                            <Price>{price?.display}</Price>
                            { distance && (
                                <Distance role="text" aria-label={`${distance} mins walking distance`}>
                                    <Sprite iconId={SPRITES.WALKING} />
                                    &nbsp;{distance}
                                </Distance>
                            )}
                        </Inline>
                    </Stack>
                </MealCardInfoPanel>
                <MealCardImageWrapper>
                    <MealCardPicture
                        templatedUrl={mainImage?.templatedUrl}
                        lazy={true}
                        placeholder={placeholderSrc}
                        size="1280x720"
                        options="fit=cover"
                        alt={name}
                        title={name}
                    />
                </MealCardImageWrapper>
            </MealCardBody>
        </MealCard>
    );
});

export default View;
