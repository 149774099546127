import useSearch from '../../../../util/search/useSearch';
import { getSearchText, getLoactionText } from '../../../../util/search/';

const cuisines = [
    "Japanese",
    "Italian",
    "Chinese",
    "Indian",
    "Mexican",
    "Thai",
    "Korean",
    "Greek",
    "Turkish",
    "French",
    "Spanish"
];

const dishes = [
    "Ramen",
    "Parma",
    "Pizza",
    "Pasta",
    "Fried Rice",
    "Burrito",
    "Satay",
    "Tacos",
    "Banh Mi",
    "Curry",
    "Katsu",
    "Dumplings"
];

const locations = [
    "Melbourne, VIC 3000",
    "Sydney, NSW 1000",
    "Perth, WA 6000",
    "Brisbane, QLD 4000",
    "Hobart, TAS 7000",
    "Adelaide, SA 5000",
    "Darwin, NT 0800"
];

const generateCuisines = (searchQuery) => {
    const { filters: { location } } = searchQuery;
    const locationText = getLoactionText(location);

    return {
        title: 'Cuisines',
        subtitle: `Popular cuisines ${locationText}`,
        items: cuisines.map(cusine => ({
            name: `${cusine} food ${locationText}`,
            path: `/meals/?keywords=${cusine.replace(/ /g, '+')}`
        }))
    }
}

const generateDishes = (searchQuery) => {
    const {filters: {location}} = searchQuery;
    const locationText = getLoactionText(location);

    return {
        title: 'Meals',
        subtitle: `Popular meals ${locationText}`,
        items: dishes.map(dish => ({
            name: `${dish} ${locationText}`,
            path: `/meals/meal-${dish.replace(/ /g, '+')}/`
        }))
    }
}

const generateLocations = (searchQuery) => {
    const {
        channel,
        filters: {
            mealTypes,
            keywords
        }
    } = searchQuery;
    const searchText = getSearchText(channel, mealTypes, keywords);

    const all = [
        ...mealTypes,
        ...keywords
    ];

    const meals = all.length ? `meal-${all.join('-')}-` : '';

    return {
        title: 'Locations',
        subtitle: `Popular ${searchText} locations`,
        items: locations.map(location => ({
            name: `${searchText} near ${location}`,
            path: `/meals/${meals}near-${location.replace(/ /g, '+')}/`
        }))
    }
}

const adaptData = ({ search }) => {
    const { searchQuery } = useSearch();

    return {
        sections: [
            generateCuisines(searchQuery),
            generateDishes(searchQuery),
            generateLocations(searchQuery)
        ]
    }
};

export default adaptData;
