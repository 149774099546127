import React, { useContext } from 'react';
import View from './View';
import useSearch from '../../util/search/useSearch';
import adaptData from './adaptData';

const MealCard = React.forwardRef((props, ref) => {
    const { searchQuery } = useSearch();

    return <View {...adaptData(props, searchQuery)} {...props} ref={ref} />
});

export default MealCard;
