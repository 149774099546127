import { success, serverError, notFound } from '../util/Response';
import { none } from '../util/functional/Option';

const hasGraphQLErrors = apolloQueryResult => apolloQueryResult.errors && apolloQueryResult.errors.length > 0;

export default (hasData) => (apolloQueryResult) => {
    if(hasData(apolloQueryResult.data)) {
        return success(apolloQueryResult.data);
    }

    if(hasGraphQLErrors(apolloQueryResult)) {
        const errors = apolloQueryResult.errors;

        return serverError({
            message: JSON.stringify({
                message: "GraphqlQL api error",
                errors
            }),
            error: none
        });
    }

    return notFound;
};
